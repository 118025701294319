<template>
  <div class="edit-profile-container">
    <error :errors="authError" />
    <b-overlay :show="busy" rounded="sm">
    <portlet :title="$t('PAGES.PROFILE.EDIT.title')" foot-class="d-flex gap-10">
      <template slot="body">
          <b-form-group id="fieldset-3" :description="$t('PAGES.PROFILE.EDIT.LABEL.avatar.desc')" :label="$t('PAGES.PROFILE.EDIT.LABEL.avatar.label')" >
            <avatar-uploader field="avatar" :image-url="avatarUrl" :preview-url="previewUrl" :upload-form="{strategy: 'avatar'}" :upload-success="onUploadSuccess" @cancel="cancelUploadAvatar" />
          </b-form-group>
          <div class="row">
            <div class="col-md-6">
              <b-form-group :state="validateState('nickname')" aria-describedby="nickname-live-feedback" id="fieldset-1" :description="$t('PAGES.PROFILE.EDIT.DESCRIPTION.nickname')" :label="$t('PAGES.PROFILE.EDIT.LABEL.nickname')" label-for="nickname">
                <b-form-input :placeholder="currentAdmin.nickname" v-model="user.nickname" id="nickname" trim></b-form-input>
                <b-form-invalid-feedback id="nickname-live-feedback">
                  {{ $t('PAGES.PROFILE.EDIT.FEEDBACK.nickname') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group id="fieldset-2" :state="validateState('phone')" aria-describedby="phone-live-feedback" :description="$t('PAGES.PROFILE.EDIT.DESCRIPTION.phone')" :label="$t('PAGES.PROFILE.EDIT.LABEL.phone')" label-for="phone">
                <b-form-input :placeholder="currentAdmin.phone" v-model="user.phone" id="phone" trim></b-form-input>
                <b-form-invalid-feedback id="phone-live-feedback">
                  {{ $t('PAGES.PROFILE.EDIT.FEEDBACK.phone') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
          </div>
      </template>
      <template v-slot:foot>
        <b-button id="submit" @click="onSubmit" variant="primary">
          {{ $t('PAGES.PROFILE.EDIT.BUTTON.submit') }}
        </b-button>
        <b-button class="ml-2" type="reset" variant="danger">
          {{ $t('PAGES.PROFILE.EDIT.BUTTON.cancel') }}
        </b-button>
      </template>
    </portlet>
    </b-overlay>
  </div>
</template>

<script>
  import Portlet from "../../../partials/content/Portlet";
  import AvatarUploader from "../../../partials/layout/AvatarUploader";
  import Error from "../../../partials/widgets/Error";
  import { mapGetters, mapState } from "vuex";
  import { validationMixin } from "vuelidate";
  import { minLength } from "vuelidate/lib/validators";
  import { regex } from 'vuelidate/lib/validators/common'
  import {UPDATE_ADMIN} from "@/store/modules/auth.module";
  import { SET_BREADCRUMB } from "@/store/modules/breadcrumbs.module";
  const phone = regex('phoneNumber', /^1(3|4|5|7|8)\d{9}$/);
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
  export default {
    name: "index",
    mixins: [validationMixin, sweetAlertMixin],
    validations: {
      formData: {
        nickname: { minLength: minLength(2) },
        phone: { phone }
      }
    },
    components: { Portlet, AvatarUploader, Error },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: this.$t('PAGES.PROFILE.EDIT.BREAD_CRUMBS_TITLES.FIRST_TITLE'), route: "/profile" },
        { title: this.$t('PAGES.PROFILE.EDIT.BREAD_CRUMBS_TITLES.CURRENT_TITLE') }
      ]);
      this.user.nickname = this.currentAdmin.nickname;
    },
    computed: {
      ...mapGetters(['currentAdmin', 'authError']),
      avatarUrl: {
        get(){
          return this.user.avatar ? this.user.avatar : this.currentAdmin.avatar;
        },
        set(v){
          this.user.avatar = v;
          return v;
        },
      },
      previewUrl: {
        get(){
          return this.preview ? this.preview : this.currentAdmin.avatar_url;
        },
        set(v){
          this.preview = v;
          return v;
        },
      }
    },
    data(){
      return {
        user: {
          nickname: '',
          phone: '',
          avatar: '',
        },
        preview: '',
        busy: false
      };
    },
    methods: {
      onUploadSuccess(info){
        this.avatarUrl = info.path;
        this.previewUrl = info.url;
        this.user.avatar = info.url;
      },
      cancelUploadAvatar(info){
        this.avatarUrl = info.oldImage;
        this.previewUrl = info.oldPreview;
      },
      onSubmit(){
        this.$v.formData.$touch();
        if (this.$v.formData.$anyError) {
          this.warning({ message: this.$t('PAGES.PROFILE.EDIT.TIPS.VALIDATION_TIPS') });
          return;
        }
        this.submitState();
        if ( ! this.user.phone ){
          this.user.phone = this.currentAdmin.phone;
        }
        this.busy = true;
        this.$store.dispatch(UPDATE_ADMIN, this.user)
          .then(()=>{
            this.submitState(false);
            this.busy = false;
            this.success({ message: this.$t('PAGES.PROFILE.EDIT.TIPS.EDIT_SUCCESS')});
          })
          .catch(()=>{this.busy = false; this.submitState(false);})
      },
      submitState(action = true){
        let submitBtn = document.getElementById("submit");
        if ( action ){
          submitBtn.classList.add(
            "kt-spinner",
            "kt-spinner--light",
            "kt-spinner--right"
          );
        }else {
          submitBtn.classList.remove(
            "kt-spinner",
            "kt-spinner--light",
            "kt-spinner--right"
          );
        }
      },
      validateState(name, parent = null) {
        if ( parent ){
          const { $dirty, $error } = this.$v.formData[parent][name];
          return $dirty ? !$error : null;
        }
        const { $dirty, $error } = this.$v.formData[name];
        return $dirty ? !$error : null;
      },
    }
  }
</script>

<style scoped>

</style>
